$body-bg: #f2f2f2;
$border: #d5d5d5;
$bg-light: #f4f5f7;

$body-color: #343434;
$text-muted: #848484;

$primary: #ecb312;
$primary-rgb: 236, 179, 18;
$secondary: #ededed;
$success: #6ec30b;
$danger: #ef5757;
$light: #f2f2f2;
$dark: #343434;
$dark-rgb: 52, 52, 52;

$border-radius: 3px;

$btn-padding-y: 0.75rem;
$btn-padding-x: 1.25rem;

// Form
$input-border-color: #d0d0d0;

@import "./variables";
@import "~bootstrap/scss/bootstrap";

html,
body {
  font-size: 14px;
  padding: 0;
  margin: 0;
  font-family: Noto Sans JP, "Helvetica Neue", Arial,
    "Hiragino Kaku Gothic ProN", "Hiragino Sans", "BIZ UDPGothic", Meiryo,
    sans-serif;
}
p,
a {
  overflow: break-word;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.container {
  padding: 0 2rem;
}

.after-none:after,
.after-none *:after {
  display: none;
}

.main {
  min-height: 90vh;
  @media (max-width: 577px) {
    min-height: 91vh;
  }
  // flex: 1;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
}

#header-dropdown {
  #header-company-name {
    min-width: 96px;
  }
}

.sidebar-wrap {
  height: 100%;
  min-height: 90vh;
}
.sidebar {
  .list-group-item {
    border: none;
    border-bottom: 1px solid #5d5d5d;
    padding: 2rem 2rem;
    .sidebar-item-icon {
      color: $primary;
    }
    &.active {
      .sidebar-item-icon {
        color: $white;
      }
    }
  }
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  font-size: 10px;
}

@for $i from 1 through 300 {
  .mxw-#{$i} {
    max-width: #{$i}px;
  }
}

@for $i from 1 through 300 {
  .mnw-#{$i} {
    min-width: #{$i}px;
  }
}

.radio-tab-style {
  .form-check {
    margin-bottom: 0;
  }
  .form-check-input {
    opacity: 0;
  }
  label {
    padding-bottom: 16px;
  }
  .form-check-input:checked[type="radio"] + label {
    border-bottom: 4px solid $primary;
  }
}

/* override bs */
.form-control,
.form-select {
  background-color: #fff;
  line-height: 2.2;
}

.modal {
  &-header {
    .modal-title {
      display: inline-block;
      margin: 0 auto;
    }
    .btn-close {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
  &-body {
    padding-bottom: 0;
  }
  &-footer {
    padding-top: 0;
    padding-bottom: 1.5rem;
    border-top: none;
    justify-content: center;
    padding-left: 4rem;
    padding-right: 4rem;
    .btn {
      flex: 1;
    }
  }
}

.table thead {
  background: #fff;
  &.bg-detail-gray {
    background: #d0d0d0;
  }
}

.table-bordered > :not(caption) > * {
  border-bottom: none;
  border-top: none;
}

.table-bordered > :not(caption) > * > * {
  border-left: none;
  border-right: none;
}

.table-striped > tbody > tr.bg-detail-gray > * {
  background: #d0d0d0 !important;
  --bs-table-accent-bg: none;
}

.table-striped > tbody:nth-of-type(odd) > * {
  background-color: #f7f7f7;
}
.table-striped > tbody:nth-of-type(even) > * {
  background-color: #fff;
}

.table > thead > :not(caption) {
  padding: 0.5rem 0.5rem;
}
.table > tbody > :not(caption) > * {
  padding: 1.5rem 1.5rem;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  box-shadow: none;
}

.table tr {
  vertical-align: middle;
}

.table > :not(:first-child) {
  border-top: 1px solid #d5d5d5;
}

/* override bs Switches */
@mixin switch($res: "sm") {
  $index: 1rem;
  $mainVal: 1rem;
  @if $res == "md" {
    $index: 2rem;
    $mainVal: 1.5rem;
  } @else if $res == "lg" {
    $index: 3rem;
    $mainVal: 2rem;
  } @else if $res == "xl" {
    $index: 4rem;
    $mainVal: 2.5rem;
  }
  margin-bottom: $index / 2;

  .form-check-input {
    height: $mainVal;
    width: calc(#{$index} + 0.75rem);
    border-radius: $mainVal * 2;
  }
}
.form-check-input {
  clear: left;
}
.form-switch.form-switch-sm {
  @include switch();
}
.form-switch.form-switch-md {
  @include switch("md");
}
.form-switch.form-switch-lg {
  @include switch("lg");
}
.form-switch.form-switch-xl {
  @include switch("xl");
}

/* util */
.position-tr {
  top: 0;
  right: 0;
}

.ex-mutiple :after {
  content: "×";
  display: block;
  position: relative;
  right: -33px;
  top: 60%;
  height: 100%;
  width: 20px;
  text-align: center;
  font-size: 26px;
}

.scr-x {
  overflow-x: scroll;
  white-space: pre;
  padding-bottom: 2px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
    background-color: #999;
    border-radius: 2px;
  }
}

.bg-light-gray {
  background: #f7f7f7;
}
.bg-detail-gray {
  background: #d0d0d0;
}
